import { combineReducers } from "redux";
import menuscript from './manuscript';
import global from './global'
import screen from './screen'

export default combineReducers({ 
  menuscript,
  screen,
  global
});
