import React, {Component, Suspense} from 'react';
import {HashRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import NotFound from 'pages/NotFound'
import Page from 'layout/page'

class RouterView extends Component {
  // async componentWillMount() {
  //   await this.props.dispatch(authActions.getAuthInfo());
  // }
  constructor(props) {
    super(props)
    console.log(props)
  }

  render() {
      return (
        <Router>
          <Switch>
            {
              this.props.routers && this.props.routers.map((item, index) => {
                const DynamicComponent = item.component;
                return <Route key={index} path={item.path} exact={item.exact} render={props => (
                  <Page {...props}>
                    <Suspense fallback={<div className={styles.spin}></div>}>
                    {
                      item.auth
                        ? (
                          item.component.component === 'Redirect'
                            ? <Redirect to={item.component.to} />
                            : <DynamicComponent {...props} routers={item.routers} />
                        )
                        : (<Redirect to={{ pathname: "/", state: {from: props.location} }}/>)
                    }
                    </Suspense>
                  </Page>
                )}>
                </Route>
              })
            }
            <Route component={NotFound}/>
          </Switch>
        </Router>
      );
  }
}

const styles = {
  spin: {
    textAlign: 'center',
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    marginBottom: '20px',
    padding: '30px 50px',
    margin: '20px 0',
  }
}
export default RouterView;

