import React from 'react';
import { Result } from 'antd-mobile'

const myImg = src => <img src={src} style={styles} alt="" />;
const styles = {
  width: '60px',
  height: '60px'
}

class NodeFound extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
  }

  render() {
    return(
      <div>
      <Result
        img={myImg('https://gw.alipayobjects.com/zos/rmsportal/GIyMDJnuqmcqPLpHCSkj.svg')}
        title="404NotFound"
        message="您访问的页面未找到！"
      />
      </div>
    )
  }
}

export default NodeFound;