import React from 'react';
const routers = [
  {
    path: "/bindcard", 
    name: "bindCard",
    component: React.lazy(() => import('pages/bindCard')),
    exact: false,
    auth: true,
  },
  {
    path: "/bindsuccess", 
    name: "bindSuccess",
    component: React.lazy(() => import('pages/bindSuccess')),
    exact: false,
    auth: true,
  },
  {
    path: "/medical", 
    name: "medical",
    component: React.lazy(() => import('pages/medicalList')),
    exact: false,
    auth: true,
  },
  {
    path: "/information", 
    name: "information",
    component: React.lazy(() => import('pages/information')),
    exact: false,
    auth: true,
  },
  {
    path: "/history", 
    name: "history",
    component: React.lazy(() => import('pages/medicalHistory')),
    exact: false,
    auth: true,
  },
  {
    path: "/login", 
    name: "login",
    component: React.lazy(() => import('pages/login')),
    exact: false,
    auth: true,
  },
]

export default routers;