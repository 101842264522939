import { SET_SITE, SET_CATEGORY_TYPE } from "../actions/actionTypes";

const initialState = {
  siteList: [],
  categoryType: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SITE: {
      const siteList = action.payload;
      return {
        ...state,
        siteList
      };
    }
    case SET_CATEGORY_TYPE: {
      const categoryType = action.payload;
      return {
        ...state,
        categoryType
      };
    }
    default:
      return state;
  }
}
