import '@babel/polyfill'
import React, {Component } from 'react';
import routers from 'router/index'
import RouterView from './router/Router'
import './App.css'
// import { ConfigProvider } from 'antd'
// import zhCN from 'antd/lib/locale-provider/zh_CN';
// import 'moment/locale/zh-cn'
import 'antd-mobile/dist/antd-mobile.css'

class App extends Component {
  constructor(props) {
    super(props)
    console.log(props)
  }
  async componentWillMount() {
    let powers = {
      check:'CHECK,CHECK_POST,ADMIN,MANAGER',
      publish: 'EDITOR_POST,CHECK_POST,ADMIN,MANAGER',
      site:'ADMIN,MANAGER',
    }
    localStorage.setItem('powers', JSON.stringify(powers))
    if(localStorage.getItem('token') && localStorage.getItem('userInfo')) {

    }else {
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo')
    }
  }

  render() {
    return (
      <RouterView routers={routers} />
    );
  }
}
export default App;