import React from 'react';

class Page extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
  }

  render() {
    return(
      <div className={styles.layout}>
        {this.props.children}
      </div>
    )
  }
}
const styles = {
  layout: {
    
  }
}

export default Page;