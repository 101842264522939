import { GET_MANUSCRIPT_PUBLISH, GET_MANUSCRIPT_REVIEWED, GET_MANUSCRIPT_REVIEWING, GET_MANUSCRIPT_DRAFTS, SET_MANUSCRIPT_MENU } from "../actions/actionTypes";

const initialState = {
  publish: {},
  reviewed: {},
  reviewing: {},
  drafts: {},
  menuList: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_MANUSCRIPT_PUBLISH: {
      const publish = action.payload;
      return {
        ...state,
        publish
      };
    }
    case GET_MANUSCRIPT_REVIEWED: {
      const reviewed = action.payload;
      return {
        ...state,
        reviewed
      };
    }
    case GET_MANUSCRIPT_REVIEWING: {
      const reviewing = action.payload;
      return {
        ...state,
        reviewing
      };
    }
    case GET_MANUSCRIPT_DRAFTS: {
      const drafts = action.payload;
      return {
        ...state,
        drafts
      };
    }
    case SET_MANUSCRIPT_MENU: {
      const menuList = action.payload;
      return {
        ...state,
        menuList
      };
    }
    default:
      return state;
  }
}
