export const LOGIN = "LOGIN";
export const USERINFO = "USERINFO";
export const APPINFO = "APPINFO";

export const GET_MANUSCRIPT_PUBLISH = "GET_MANUSCRIPT_PUBLISH";
export const GET_MANUSCRIPT_REVIEWED = "GET_MANUSCRIPT_REVIEWED";
export const GET_MANUSCRIPT_REVIEWING = "GET_MANUSCRIPT_REVIEWING";
export const GET_MANUSCRIPT_DRAFTS = "GET_MANUSCRIPT_DRAFTS";
export const SET_MANUSCRIPT_MENU = "SET_MANUSCRIPT_MENU";

export const GET_MATTER_MENU_LIST = "GET_MATTER_MENU_LIST";

export const SET_SITE = "SET_SITE";
export const SET_CATEGORY_TYPE = "SET_CATEGORY_TYPE"

export const DEPT_LIST = 'DEPT_LIST'
export const CONDITION = 'CONDITION'
