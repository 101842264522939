import { DEPT_LIST, CONDITION } from "../actions/actionTypes";

const initialState = {
  deptList: [],
  condition: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DEPT_LIST: {
      const deptList = action.payload;
      return {
        ...state,
        deptList
      };
    }
    case CONDITION: {
      const condition = action.payload;
      return {
        ...state,
        condition
      };
    }
    default:
      return state;
  }
}